import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Background from '../assets/dragons/background.png';
import Hero from '../assets/dragons/hero.png';
import Logo from '../assets/dragons/logo.png';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 14px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        backgroundSize: 'cover',
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(rgba(0, 0, 0, 0), #0D0D0D, #0D0D0D)',
    },
    content: {
        width: '100%',
        height: '100%',
        background: '#FFF',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        margin: '40px 32px 90px 32px',

        [theme.breakpoints.up('md')]: {
            width: '862px',

        },

        [theme.breakpoints.up('lg')]: {
            width: '982px',
            flexDirection: 'row',
            margin: '40px 0px 90px 0px',
        }
    },
    hero: {
        margin: '34px 26px 0px 26px',

        '& img': {
            width: '100%',

            [theme.breakpoints.up('lg')]: {
                width: '417px',
            }
        },

        [theme.breakpoints.up('lg')]: {
            margin: '34px 26px 44px 26px',
        }
    },
    textContent: {
        textAlign: 'left',
        padding: '26px 26px 44px 26px',

        [theme.breakpoints.up('lg')]: {
            padding: '46px 76px 0px 26px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#7B3938',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#2D2824',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#2D2824',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        width: '50%',
        textAlign: 'left',
    },
    logo: {
        width: '50%',
        textAlign: 'right',

        '& img': {
            width: '111px',

            [theme.breakpoints.up('lg')]: {
                width: '154px',
            }
        }
    }
});

class DragonsPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.content}>
                        <div className={classes.hero}><img src={Hero} /></div>
                        <div className={classes.textContent}>
                            <div className={classes.title}>Dragons Club Kids Classes</div>

                            <div className={classes.subtitle}>The Adelaide Wing Chun Academy runs specific classes for kids</div>

                            <div className={classes.text}>"Dragons Club" a program designed to teach children between the ages of 5 to 12 years, the basis of self defence based on Wing Chun concepts, as well as other specific child oriented awareness strategies. They will learn self discipline, self defence, self respect, and how to exercise, keep fit and maintain active development</div>

                            <div className={classes.footer}>
                                <div className={classes.btn}><Link to="/dragonsclasses"><Button variant="contained">Dragons Classes</Button></Link></div>
                                <div className={classes.logo}><img src={Logo} /></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(DragonsPanel)