import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoSmall from "../assets/logo_small.png";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

const muiTheme = createTheme({
    palette: {
        custom: {
            main: '#FFF',
        },
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#0D0D0D",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '4px 12px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '1000',
        position: 'sticky',
        top: '0',
        width: '100%',
        height: '75px',
        display: 'flex',
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        color: '#FFF',
        padding: '0 10px 0 10px',
    },
    menu: {
      padding: '0 10px',

      '& a': {
          padding: '2px 4px',
          fontFamily: 'montserratbold',
          textTransform: 'uppercase',
          color: '#FFF',
          textDecoration: 'none',
          background: 'none',
      },
      '& a:hover': {
          background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
      },

      '& $menuActive': {
          background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
      }
    },
    menuActive: {
    },
    menubtn: {
        padding: '0 10px',
    },
    menusLeft: {
        display: 'flex',
        alignItems: 'center',
        height: '75px',
    },
    menusRight: {
        display: 'flex',
        alignItems: 'center',
        height: '75px',
    },
    logoSmall: {
        position: 'absolute',
        left: 'calc(50% - 172px)',
        margin: '8px 0 0 0',
    },
    menusSmall: {
        padding: '0 0 0 0',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    menusLarge: {
        display: 'none',
        alignItems: 'center',

        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        }
    },
    drawerPanel: {
        color: '#FFF',
        padding: '20px 27px',

        '& $menu': {
            padding: '10px 0',
        },

        '& $menubtn': {
            padding: '20px 0 10px 0',
        }
    },
    svgIcons: {
        transform: 'scale(1.4)'
    }
});

class HeaderPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        function RenderMenu(props) {
            if (props.page == props.currPage) {
                return (
                    <div className={classes.menu}><Link className={classes.menuActive} to={props.to}>{props.title}</Link></div>
                )
            }

            return (
                <div className={classes.menu}><Link to={props.to}>{props.title}</Link></div>
            )
        }

        const toggleDrawer = (newOpen: boolean) => () => {
            self.setState({ open: newOpen });
        };

        const DrawerList = (
            <Box role="presentation" onClick={toggleDrawer(false)}>
                <div className={classes.drawerPanel}>
                    <RenderMenu to={"/about"} title={"About"} page={"about"} currPage={this.props.page} />
                    <RenderMenu to={"/classes"} title={"Classes"} page={"classes"} currPage={this.props.page} />
                    <RenderMenu to={"/instructors"} title={"Instructors"} page={"instructors"} currPage={this.props.page} />
                    <RenderMenu to={"/gallery"} title={"Gallery"} page={"gallery"} currPage={this.props.page} />
                    <RenderMenu to={"/contact"} title={"Contact"} page={"contact"} currPage={this.props.page} />
                    <div className={classes.menubtn}><Link to="/freelesson"><Button variant="contained">Book my free lesson</Button></Link></div>
                </div>
            </Box>
        );

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel} style={this.props.showLogo ? {background: '#0D0D0D'} : {}}>

                <div className={classes.content}>

                    <div className={classes.menusLeft}>
                        <div className={classes.menusLarge}>
                            <RenderMenu to={"/about"} title={"About"} page={"about"} currPage={this.props.page} />
                            <RenderMenu to={"/classes"} title={"Classes"} page={"classes"} currPage={this.props.page} />
                            <RenderMenu to={"/instructors"} title={"Instructors"} page={"instructors"} currPage={this.props.page} />
                            <RenderMenu to={"/gallery"} title={"Gallery"} page={"gallery"} currPage={this.props.page} />
                        </div>
                    </div>

                    <div className={classes.logoSmall} style={this.props.showLogo ? {} : { display: 'none' }}><Link to="/"><img src={LogoSmall}/></Link></div>

                    <div className={classes.menusRight}>
                        <div className={classes.menusLarge}>
                            <RenderMenu to={"/contact"} title={"Contact"} page={"contact"} currPage={this.props.page} />
                            <div className={classes.menubtn}><div className={classes.btn}><Link to="/freelesson"><Button variant="contained">Book my free lesson</Button></Link></div></div>
                        </div>

                        <div className={classes.menusSmall}>
                            <IconButton
                                size="large"
                                color="custom"
                                aria-label="open drawer"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon className={classes.svgIcons}/>
                            </IconButton>
                        </div>

                    </div>

                </div>

            </div>

            <Drawer anchor={'top'} open={self.state.open} onClose={toggleDrawer(false)}>
            {DrawerList}
            </Drawer>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(HeaderPanel)