import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../assets/about/24WingChunAcademyLR-180.jpg";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '76px 46px 0px 46px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 64px 50px',
        },
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 26px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '64px 0px 64px 66px',
        },
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
});

class GeneralPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.panelContent}>

                    <div className={classes.content}>
                        <div className={classes.photoContent}>
                            <div className={classes.photo}><img src={HeroImage} /></div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.subtitle}>Both Sifu’s were previously senior instructors under Grandmaster Jim Fung’s Wing Chun Linage, until this organisation closed down its Adelaide academy in 2013.</div>
                            <div className={classes.text}>
                                They decided to open up their own academy so both existing and new students in Adelaide could continue learning an effective system of martial arts and self-defence and gain a deeper knowledge of Wing Chun  and martial art
                                <br /><br />
                                The Academy follows the teachings passed on by Chu Shong Tin and Jim Fung, plus the continual evolution from the head instructors' decades of knowledge gained from studying, exploring and teaching the Wing Chun system. Sifu's Tony Psaila and Damian Rainer are both recognised by the Ving Tsun Athletic Association Hong Kong as Sifu's of the Wing Chun System. They regularly traveled to Hong Kong to train with Grandmaster Chu Shong Tin, and the greater Wing Chun family.
                                <br /><br />
                                The Adelaide Wing Chun Academy is a member of the Australian Wing Chun Federation, a collection of Wing Chun clubs around Australia that stem from the same linage.
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(GeneralPanel)