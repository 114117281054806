import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import CaligraphyImage from "../assets/caligraphy_dark.png";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'url(' + CaligraphyImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '183px 356px',
        backgroundPosition: 'calc(0% - 34px) calc(50% + 160px)',

        [theme.breakpoints.up('md')]: {
            backgroundSize: '305px 593px',
            backgroundPosition: 'calc(50% - 390px) 190px',
        },

        [theme.breakpoints.up('lg')]: {
            backgroundPosition: 'calc(50% - 440px) 180px',
        }
    },
    content: {
        width: '100%',
        height: '100%',
        background: '#F4EFEA',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '80px 32px 200px 32px',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            width: '982px',
            flexDirection: 'row',
            margin: '120px 32px 160px 32px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '0px 26px 32px 26px',

        [theme.breakpoints.up('lg')]: {
            padding: '86px 0px 0px 26px',
        },
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#7B3938',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#2D2824',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#2D2824',
    },
    btn: {
        textAlign: 'left',
    },
    mapContent: {
        margin: '26px',
        border: '5px solid #FFE0BD',

        [theme.breakpoints.up('lg')]: {
            margin: '40px',
            width: '468px',
            height: '290px',
        },

        "& img": {
            width: '100%',
        }
    },
});

class MapPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effectLogo}>

                <div className={classes.content}>
                    <div className={classes.mapContent}>
                        <img src="https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-l+ff2e28(138.59296393556474,-34.932684761234526)/138.5934,-34.9326,16.41,0/468x290?access_token=pk.eyJ1IjoibWFsbGJldXJ5IiwiYSI6IjJfV1MzaE0ifQ.scrjDE31p7wBx7-GemqV3A" />
                    </div>

                    <div className={classes.textContent}>
                        <div className={classes.title}>Find us here!</div>

                        <div className={classes.subtitle}>St Luke's Hall</div>

                        <div className={classes.text}>35 Whitmore Square<br />Adelaide SA 5000</div>

                        <div className={classes.btn}><Link to="/contact"><Button variant="contained">Contact us</Button></Link></div>
                    </div>

                </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(MapPanel)