import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../assets/about/24WingChunAcademyLR-158.jpg";

const muiTheme = createTheme({
    palette: {
        custom: {
            main: '#FFF'
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#009BB5",
                }
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        marginTop: '0px',
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#0D0D0D',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            width: '1082px',
        }
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
            width: '100%',
            minHeight: '468px',
            alignItems: 'start',
            flexDirection: 'row',
        }
    },
    textContent: {
        zIndex: '101',
        textAlign: 'left',
        padding: '26px 16px 46px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            width: '35%',
            padding: '150px 0px 36px 50px',
        },
    },
    textContentOverflow: {
        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    photo: {
        position: 'relative',
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('md')]: {
            '&::after': {
                content: '" "',
                left: '0px',
                width: '50%',
                height: '100%',
                position: 'absolute',
                background: 'linear-gradient(90deg, #0D0D0D, rgba(0, 0, 0, 0))',
            },
        },

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    photoContent: {
        [theme.breakpoints.up('lg')]: {
            width: '65%',
            zIndex: '100',
            height: '100%',
            minHeight: '100%',
            display: 'flex',
            backgroundSize: 'cover',
            backgroundImage: 'url(' + HeroImage + ')',
            backgroundRepeat: 'no-repeat',
        },
    },
    photoEffectLeft: {
        display: 'flex',
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(90deg, #0D0D0D, rgba(0, 0, 0, 0))',
        },
    },
    photoEffectRight: {
        display: 'flex',
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(270deg, #0D0D0D 10%, rgba(13, 13, 13, 0.5) 25%, rgba(13, 13, 13, 0) 65%)',
        },
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '3.2em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#EFC28F',
    },
    text: {
        color: '#EFC28F',
    },
});

class AboutHeroPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>

                <div className={classes.content}>

                    <div className={classes.panelContent}>

                        <div className={classes.photo}><img src={HeroImage} /></div>

                        <div className={classes.textContent}>

                            <div className={classes.textContentOverflow}>
                                <div className={classes.title}>About Us</div>
                                <div className={classes.subtitle}>History of the Adelaide Wing Chun Academy</div>
                                <div className={classes.text}>The Adelaide Wing Chun Academy opened its doors to teach Wing Chun Kung Fu to the people of Adelaide in 2013. The Academy was founded by Sifu Tony Psaila and Sifu Damian Rainer.</div>
                            </div>

                        </div>

                        <div className={classes.photoContent}><div className={classes.photoEffectLeft}></div><div className={classes.photoEffectRight}></div></div>

                    </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(AboutHeroPanel)