import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import WelcomePage from './components/WelcomePage';
import AboutPage from './components/AboutPage';
import ClassesPage from './components/ClassesPage';
import DragonsClassesPage from './components/DragonsClassesPage';
import GalleryPage from './components/Pages/Gallery/GalleryPage';
import ContactPage from './components/ContactPage';
import FreeLessonPage from './components/FreeLessonPage';
import SifusPage from './components/SifusPage';
import SifuTonyPage from './components/SifuTonyPage';
import SifuDamianPage from './components/SifuDamianPage';
import SpecialPage from './components/SpecialPage';
import MembershipPage from './components/Pages/Membership/MembershipPage';
import MembershipThanksPage from './components/Pages/Membership/MembershipThanksPage';

import './cufonfonts.css';
import './stylesheet.css';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={WelcomePage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/classes" exact component={ClassesPage} />
          <Route path="/dragonsclasses" exact component={DragonsClassesPage} />
          <Route path="/instructors" exact component={SifusPage} />
          <Route path="/sifutony" exact component={SifuTonyPage} />
          <Route path="/sifudamian" exact component={SifuDamianPage} />
          <Route path="/gallery" exact component={GalleryPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/freelesson" exact component={FreeLessonPage} />
          <Route path="/special" exact component={SpecialPage} />
          <Route path="/membership" exact component={MembershipPage} />
          <Route path="/membershipthanks" exact component={MembershipThanksPage} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
