import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

import GalleryImage1 from "../../../assets/gallery/24WingChunAcademyLR-5.jpg";
import GalleryImage2 from "../../../assets/gallery/24WingChunAcademyLR-10.jpg";
import GalleryImage3 from "../../../assets/gallery/24WingChunAcademyLR-13.jpg";
import GalleryImage4 from "../../../assets/gallery/24WingChunAcademyLR-20.jpg";
import GalleryImage5 from "../../../assets/gallery/24WingChunAcademyLR-30.jpg";
import GalleryImage6 from "../../../assets/gallery/24WingChunAcademyLR-35.jpg";
import GalleryImage7 from "../../../assets/gallery/24WingChunAcademyLR-41.jpg";
import GalleryImage8 from "../../../assets/gallery/24WingChunAcademyLR-46.jpg";
import GalleryImage9 from "../../../assets/gallery/24WingChunAcademyLR-48.jpg";
import GalleryImage10 from "../../../assets/gallery/24WingChunAcademyLR-53.jpg";
import GalleryImage11 from "../../../assets/gallery/24WingChunAcademyLR-72.jpg";
import GalleryImage12 from "../../../assets/gallery/24WingChunAcademyLR-91.jpg";
import GalleryImage13 from "../../../assets/gallery/24WingChunAcademyLR-100.jpg";
import GalleryImage14 from "../../../assets/gallery/24WingChunAcademyLR-120.jpg";
import GalleryImage15 from "../../../assets/gallery/24WingChunAcademyLR-123.jpg";
import GalleryImage16 from "../../../assets/gallery/24WingChunAcademyLR-132.jpg";
import GalleryImage17 from "../../../assets/gallery/24WingChunAcademyLR-137.jpg";
import GalleryImage18 from "../../../assets/gallery/24WingChunAcademyLR-158.jpg";
import GalleryImage19 from "../../../assets/gallery/24WingChunAcademyLR-176.jpg";
import GalleryImage20 from "../../../assets/gallery/24WingChunAcademyLR-178.jpg";
import GalleryImage21 from "../../../assets/gallery/24WingChunAcademyLR-180.jpg";
import GalleryImage22 from "../../../assets/gallery/24WingChunAcademyLR-225.jpg";
import GalleryImage23 from "../../../assets/gallery/24WingChunAcademyLR-228.jpg";
import GalleryImage24 from "../../../assets/gallery/24WingChunAcademyLR-243.jpg";
import GalleryImage25 from "../../../assets/gallery/24WingChunAcademyLR-246.jpg";
import GalleryImage26 from "../../../assets/gallery/24WingChunAcademyLR-253.jpg";
import GalleryImage27 from "../../../assets/gallery/24WingChunAcademyLR-256.jpg";
import GalleryImage28 from "../../../assets/gallery/24WingChunAcademyLR-262.jpg";
import GalleryImage29 from "../../../assets/gallery/24WingChunAcademyLR-264.jpg";
import GalleryImage30 from "../../../assets/gallery/24WingChunAcademyLR-266.jpg";
import GalleryImage31 from "../../../assets/gallery/24WingChunAcademyLR-272.jpg";
import GalleryImage32 from "../../../assets/gallery/24WingChunAcademyLR-277.jpg";
import GalleryImage33 from "../../../assets/gallery/24WingChunAcademyLR-280.jpg";

const slides = [
    { src: GalleryImage1 },
    { src: GalleryImage2 },
    { src: GalleryImage3 },
    { src: GalleryImage4 },
    { src: GalleryImage5 },
    { src: GalleryImage6 },
    { src: GalleryImage7 },
    { src: GalleryImage8 },
    { src: GalleryImage9 },
    { src: GalleryImage10 },
    { src: GalleryImage11 },
    { src: GalleryImage12 },
    { src: GalleryImage13 },
    { src: GalleryImage14 },
    { src: GalleryImage15 },
    { src: GalleryImage16 },
    { src: GalleryImage17 },
    { src: GalleryImage18 },
    { src: GalleryImage19 },
    { src: GalleryImage20 },
    { src: GalleryImage21 },
    { src: GalleryImage22 },
    { src: GalleryImage23 },
    { src: GalleryImage24 },
    { src: GalleryImage25 },
    { src: GalleryImage26 },
    { src: GalleryImage27 },
    { src: GalleryImage28 },
    { src: GalleryImage29 },
    { src: GalleryImage30 },
    { src: GalleryImage31 },
    { src: GalleryImage32 },
    { src: GalleryImage33 },
];

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        margin: '30px 0 80px 0',
        padding: '0 36px 0 36px',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            width: '1082px',
        }
    },
    photo: {
        padding: '10px 0 10px 0',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: '10px 10px 10px 10px',
            width: '320px',
        },

        '& a:hover': {
            cursor: 'pointer',
        },

        '& img': {
            width: '100%',
        },
    }
});

class GalleryPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        const toggleLighbox = (newOpen: boolean, index) => () => {
            self.setState({ slideIndex: index });
            self.setState({ open: newOpen });
        };

        function RenderPhotoGallery() {
            return (
                <div className={classes.content}>
                {slides.map((slide, index) => <div className={classes.photo} key={slide.src}><a onClick={toggleLighbox(true, index)}><img src={slide.src} /></a></div>)}
                </div>
            )
        }
        
        return (
            <ThemeProvider theme={muiTheme}>

                <div>
                <Lightbox
                    open={self.state.open}
                    close={toggleLighbox(false)}
                    index={self.state.slideIndex}
                    slides={slides}
                />
                </div>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.panelContent}>
                        <RenderPhotoGallery />
                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(GalleryPanel)