import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../assets/video/hero.png";
import Button from "@mui/material/Button";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const breakpoints = createBreakpoints({})

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '0',
                    borderRadius: '4',
                    minWidth: '0',
                    width: '48px',
                    height: '40px',

                    [breakpoints.up('md')]: {
                        width: '72px',
                        height: '60px',
                    },
                }
            }
        },
    }
});

const useStyles = theme => ({
    videoPlayer: {
        position: 'relative',
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        }
    },
    videoPlayBtn: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 24px)',

        [theme.breakpoints.up('md')]: {
            top: 'calc(50% - 30px)',
            left: 'calc(50% - 36px)',
        }
    },
    svgIcons: {
        transform: 'scale(2)',

        [theme.breakpoints.up('md')]: {
            transform: 'scale(3)',
        }
    }
});

class VideoPlayerPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.videoPlayer}>
                <img src={HeroImage} />
                <div className={classes.videoPlayBtn}><a href={"https://www.youtube.com/@adelaidewingchunacademy3590"} target="_blank"><Button variant="contained"><PlayArrowIcon className={classes.svgIcons} size="large" /></Button></a></div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(VideoPlayerPanel)