import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import HeroImage from "../assets/event/hero.png";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '64px 0px 0px 50px',
        }
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '26px 16px 106px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '46px 0px 146px 66px',
        },
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    btn: {
        width: '50%',
        textAlign: 'left',
    }
});

class EventPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.panelContent}>

                <div className={classes.content}>
                    <div className={classes.photoContent}>
                        <div className={classes.photo}><img src={HeroImage} /></div>
                    </div>

                    <div className={classes.textContent}>
                        <div className={classes.title}>Book us to visit your company or event</div>
                        <div className={classes.subtitle}>We can offer tailored packages to suit your individual needs</div>
                        <div className={classes.text}>Focusing on the development of personal self-defence, fitness, coordination and concentration, and overall improvement of confidence through many areas of life. As well as Wing Chun, our Instructors hold qualifications in Sports Coaching, First Aid and Group Training. Get in touch for testimonials and to create your unique package.</div>
                        <div className={classes.btn}><Link to="/contact"><Button variant="contained">Contact us</Button></Link></div>
                    </div>
                </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(EventPanel)