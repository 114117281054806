import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Logo from "../assets/shapes.png";
import Button from "@mui/material/Button";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 16px',
                    borderRadius: '4',
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#fff',
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#fff",
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#fff',
                        },
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                    },
                },
            },
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + Logo + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '239px 189px',
            backgroundPosition: 'calc(50% + 491px) 50%',
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '982px',
        }
    },
    textContent: {
        padding: '46px 18px 30px 18px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 170px 60px 170px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        width: '100%',
        margin: '20px 0 20px 0',
        color: '#FFF',
        textAlign: 'center',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #8C7356 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
    mobile: {
        display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    desktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    logo: {
        width: '112px',
    }
});

class ContactSpecialPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showThanks: false,
            name: '',
            validName: false,
            email: '',
            validEmail: false,
            phone: '',
            enquiry: '',
            validForm: false,
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        function handleNameInputChange(event) {
            self.setState({ name: event.target.value });
            self.setState( { validName: event.target.validity.valid });
        }

        function handleEmailInputChange(event) {
            self.setState({ email: event.target.value });
            self.setState( { validEmail: event.target.validity.valid });
        }

        function handlePhoneInputChange(event) {
            self.setState({ phone: event.target.value });
        }

        function handleEnquiryInputChange(event) {
            self.setState({ enquiry: event.target.value });
        }

        function handleSubmit(event) {
            event.preventDefault();
            console.log('Name:'+self.state.name);
            console.log('Email:'+self.state.email);
            console.log('Phone:'+self.state.phone);
            console.log('Enquiry:'+self.state.enquiry);

            const formData = new FormData();
            formData.append("submit", true);
            formData.append("name", self.state.name);
            formData.append("email", self.state.email);
            formData.append("phone", self.state.phone);
            formData.append("message", self.state.enquiry);
            formData.append("subject", "AWCA - Give the gift of Wing Chun");

            const requestOptions = {
                method: 'POST',
                body: formData
            };
            fetch('https://adelaidewingchunacademy.com.au/server/mailer.php', requestOptions)
                .then(data => {
                    console.log('GOOD');
                })

            self.setState({ showThanks: true });
        }

        let validForm = (this.state.validName && this.state.validEmail);

        return (
        <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.effectLogo}>

                        <div className={classes.content}>
                            <div className={classes.textContent}>
                                <div className={classes.title}>Your Details</div>

                                    {self.state.showThanks &&
                                        <div className={classes.text}>
                                        Thank you for your enquiry, we will get back to you as soon as we can.
                                        <br/><br/>
                                        While you wait you might like to check out our YouTube channel.

                                        <div className={classes.more}><a href={"https://www.youtube.com/@adelaidewingchunacademy3590"} target="_blank">Watch videos</a></div>
                                        </div>
                                    }

                                    {!self.state.showThanks &&
                                        <div className={classes.text}>
                                        To arrange payment and to collect your training shirt simply fill in the form and we'll get right back to you.

                                        <Box
                                        sx={{my: 2}}
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        onSubmit={handleSubmit}
                                        >

                                        <TextField
                                        sx={{my: 2}}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        id="name"
                                        label="Name"
                                        defaultValue={self.state.name}
                                        onChange={handleNameInputChange}
                                        />

                                        <TextField
                                        sx={{my: 2}}
                                        required
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        id="email"
                                        label="Email address"
                                        defaultValue={self.state.email}
                                        onChange={handleEmailInputChange}
                                        />

                                        <TextField
                                        sx={{my: 2}}
                                        fullWidth
                                        variant="outlined"
                                        id="phone"
                                        label="Phone number"
                                        defaultValue={self.state.phone}
                                        onChange={handlePhoneInputChange}
                                        />

                                        <TextField
                                        sx={{my: 2}}
                                        fullWidth
                                        variant="outlined"
                                        id="enquiry"
                                        label="Comments"
                                        multiline
                                        rows={4}
                                        defaultValue={self.state.enquiry}
                                        onChange={handleEnquiryInputChange}
                                        />

                                        <div className={classes.btn}><Button disabled={!validForm} sx={{my: 2}} type="submit" variant="contained">Submit enquiry</Button></div>

                                        * Please fill in your details so that we can respond.
                                        </Box>

                                        </div>
                                    }

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(ContactSpecialPanel)