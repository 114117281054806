import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Logo from "../assets/shapes.png";
import Button from "@mui/material/Button";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '5px 8px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    btn: {
        margin: '26px 0 26px 0',
    },
});

class BuyButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        let title = "AWCA_Membership_" + this.props.title;
        let action = "https://secure.demo.ezidebit.com.au/webddr/Request.aspx?a=530E912B-D071-4DB9-41E6-FA6489A6EAA5&debits=4" ;

        return (
            <ThemeProvider theme={muiTheme}>

            <form action={action} method="POST">

            <input name="uref" value={title} type={"hidden"} readOnly />
            <input name="rAmount" value={this.props.price} type={"hidden"} readOnly />
            <input name="Freq" value="4" type={"hidden"} readOnly />
            <input name="dur" value="1" type={"hidden"} readOnly />
            <input name="businessOrPerson" value="1" type={"hidden"} readOnly />
            <input name="debits" value="2" type={"hidden"} readOnly />
            <input name="aFreq" value={this.props.freq} type={"hidden"} readOnly />
            <input name="aDur" value="1" type={"hidden"} readOnly />

            {/*
            <input name="callback" value={"https://www.adelaidewingchunacademy.com.au/#/membershipthanks"} type={"hidden"} readOnly />
            <input name="cMethod" value="post" type={"hidden"} readOnly />
            */}

            <Button type="submit" variant="outlined" color="secondary">Buy&nbsp;${this.props.price}</Button>

            </form>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(BuyButton)