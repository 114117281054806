import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Logo from "../../../assets/shapes.png";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '5px 8px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + Logo + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '239px 189px',
            backgroundPosition: 'calc(50% + 491px) 50%',
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '982px',
        }
    },
    textContent: {
        padding: '46px 18px 30px 18px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 170px 60px 170px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        width: '100%',
        margin: '20px 0 20px 0',
        color: '#FFF',
        textAlign: 'center',

        "& table": {
            margin: '26px auto 26px auto',
            borderSpacing: '0',

            [theme.breakpoints.up('lg')]: {
                width: '580px',
            },
        },

        "& tbody tr:nth-child(odd)": {
            background: 'rgba(13, 13, 13, 0.2)',
        },

        "& th": {
            textAlign: 'left',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            }
        },

        "& td": {
            textAlign: 'left',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            },
        }
    },
    more: {
        margin: '12px 0',
        color: '#FFF',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
    btn: {
        margin: '26px 0 26px 0',
    },
    mobile: {
        display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    desktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    logo: {
        width: '112px',
    }
});

class MembershipBuyThanksPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.effectLogo}>

                    <div className={classes.content}>
                        <div className={classes.textContent}>
                            <div className={classes.title}>Thank You</div>
                            <div className={classes.text}>

                                You have now become a member of the Adelaide Wing Chun Academy!
                                <br/><br/>
                                You will shortly receive an email receipt with details of your membership.

                                <div className={classes.more}><Link to="/membership">Sign up another member</Link></div>

                            </div>

                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(MembershipBuyThanksPanel)