import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
    palette: {
        custom: {
            main: '#FFF'
        },
    },
    components: {
    }
});

const useStyles = theme => ({
    photo: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '246px',
        height: '236px',
        transition: 'opacity 2s',
        opacity: '0',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('sm')]: {
            width: '411px',
            height: '393px',
        }
    },
});

class PhotoFrameImage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false,
        }
        this.handleImageLoaded = this.handleImageLoaded.bind(this);

        this.waitingOnImage = true;
    }

    componentDidMount() {
        var self = this;
    }

    handleImageLoaded() {
        if (!this.state.imageLoaded) {
            this.props.handleImageLoaded();
        }
    }

    render() {
        const { classes } = this.props;
        let self = this;

        if (this.props.loadImage) {
            return (
                <ThemeProvider theme={muiTheme}>

                    <div className={classes.photo} style={this.props.showImage ? {opacity: '1'} : {opacity: '0'}}>
                        <img src={self.props.image} onLoad={this.handleImageLoaded} />
                    </div>

                </ThemeProvider>
            )
        }

        return (
            <ThemeProvider theme={muiTheme}>

                <div className={classes.photo} style={this.props.showImage ? {opacity: '1'} : {opacity: '0'}}>
                    <img src={self.props.image} />
                </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(PhotoFrameImage)