import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PhotoFrameImage from "./PhotoFrameImage";
import HeroImageFrame from "../assets/hero/background.png";
import ShapesImage from "../assets/shapes.png";
import CaligraphyImage from "../assets/caligraphy.png";

import HeroImage1 from "../assets/hero/tony.jpg";
import HeroImage2 from "../assets/hero/24WingChunAcademyLR-146.jpg";
import HeroImage3 from "../assets/hero/24WingChunAcademyLR-125.jpg";
import HeroImage4 from "../assets/hero/24WingChunAcademyLR-132.jpg";
import HeroImage5 from "../assets/hero/24WingChunAcademyLR-149.jpg";
import HeroImage6 from "../assets/hero/24WingChunAcademyLR-243.jpg";
import HeroImage7 from "../assets/hero/24WingChunAcademyLR-263.jpg";
import HeroImage8 from "../assets/hero/24WingChunAcademyLR-255.jpg";
import HeroImage9 from "../assets/hero/24WingChunAcademyLR-275.jpg";
import HeroImage10 from "../assets/hero/24WingChunAcademyLR-235.jpg";

const images = [
    HeroImage1,
    HeroImage2,
    HeroImage3,
    HeroImage4,
    HeroImage5,
    HeroImage6,
    HeroImage7,
    HeroImage8,
    HeroImage9,
    HeroImage10,
];

const PHOTO_SHOW_DURATION = 6000;

const muiTheme = createTheme({
    palette: {
        custom: {
            main: '#FFF'
        },
    },
    components: {
    }
});

const useStyles = theme => ({
    panel: {
        margin: '30px 0 60px 20px',

        [theme.breakpoints.up('md')]: {
            margin: '0',
        }
    },
    photoFrame: {
        position: 'relative',
        width: '246px',
        height: '236px',

        [theme.breakpoints.up('sm')]: {
            width: '411px',
            height: '393px',
        },

        '&::before': {
            content: '" "',
            backgroundImage: 'url(' + HeroImageFrame + ')',
            backgroundRepeat: 'no-repeat',
            width: '246px',
            height: '232px',
            position: 'absolute',
            top: 'calc(50% - 68px)',
            left: 'calc(50% - 163px)',

            [theme.breakpoints.up('sm')]: {
                width: '411px',
                height: '393px',
                top: 'calc(50% - 120px)',
                left: 'calc(50% - 270px)',
            }
        }
    },
    photoFrameImage: {
        position: 'relative',
    },
    shapes: {
        position: 'absolute',
        top: '-28px',
        left: '-80px',

        '& img': {
            width: '143px',

            [theme.breakpoints.up('sm')]: {
                width: '239px',
            }
        },

        [theme.breakpoints.up('sm')]: {
            top: '-48px',
            left: '-135px',
        }
    },
    caligraphy: {
        position: 'absolute',
        top: '-37px',
        right: '-30px',

        '& img': {
            width: '63px',

            [theme.breakpoints.up('sm')]: {
                width: '105px',
            },

            [theme.breakpoints.up('lg')]: {
                display: 'none',
            }
        },

        [theme.breakpoints.up('sm')]: {
            top: '-62px',
            right: '-58px',
        }
    }
});

class PhotoFramePanel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handlePhotoTimer = this.handlePhotoTimer.bind(this);

        this.state = {
            showImage1: false,
            showImage2: false,
            showImage3: false,
            showImage4: false,
            showImage5: false,
            showImage6: false,
            showImage7: false,
            showImage8: false,
            showImage9: false,
            showImage10: false,
        }
        this.currID = 0;
        this.imageID1 = 0;
        this.imageID2 = 1;
        this.imageID3 = 2;
        this.imageID4 = 3;
        this.imageID5 = 4;
        this.imageID6 = 5;
        this.imageID7 = 6;
        this.imageID8 = 7;
        this.imageID9 = 8;
        this.imageID10 = 9;
        this.waitingOnImage = true;
        this.loadImage1 = true;
        this.loadImage2 = false;
        this.loadImage3 = false;
        this.loadImage4 = false;
        this.loadImage5 = false;
        this.loadImage6 = false;
        this.loadImage7 = false;
        this.loadImage8 = false;
        this.loadImage9 = false;
        this.loadImage10 = false;
    }

    componentDidMount() {
    }

    handlePhotoTimer() {
        return this.props.handlePhotoTimer();
    }

    render() {
        const { classes } = this.props;
        let self = this;

        function checkImage() {
            if (self.waitingOnImage) {
                self.waitingOnImage = false;

                if (self.currID == self.imageID1) {
                    self.loadImage1 = false;
                    self.loadImage2 = true;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: true, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID2) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = true;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: true, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID3) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = true;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: true, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID4) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = true;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: true, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID5) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = true;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: true, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID6) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = true;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: true, showImage7: false, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID7) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = true;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: true, showImage8: false, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID8) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = true;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: true, showImage9: false, showImage10: false });
                }

                if (self.currID == self.imageID9) {
                    self.loadImage1 = false;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = true;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: true, showImage10: false });
                }

                if (self.currID == self.imageID10) {
                    self.loadImage1 = true;
                    self.loadImage2 = false;
                    self.loadImage3 = false;
                    self.loadImage4 = false;
                    self.loadImage5 = false;
                    self.loadImage6 = false;
                    self.loadImage7 = false;
                    self.loadImage8 = false;
                    self.loadImage9 = false;
                    self.loadImage10 = false;
                    self.setState({ showImage1: false, showImage2: false, showImage3: false, showImage4: false, showImage5: false, showImage6: false, showImage7: false, showImage8: false, showImage9: false, showImage10: true });
                }

                setTimeout(() => {
                    self.waitingOnImage = true;

                    if (self.currID == self.imageID1) {
                        self.currID = self.imageID2;
                    }
                    else if (self.currID == self.imageID2) {
                        self.currID = self.imageID3;
                    }
                    else if (self.currID == self.imageID3) {
                        self.currID = self.imageID4;
                    }
                    else if (self.currID == self.imageID4) {
                        self.currID = self.imageID5;
                    }
                    else if (self.currID == self.imageID5) {
                        self.currID = self.imageID6;
                    }
                    else if (self.currID == self.imageID6) {
                        self.currID = self.imageID7;
                    }
                    else if (self.currID == self.imageID7) {
                        self.currID = self.imageID8;
                    }
                    else if (self.currID == self.imageID8) {
                        self.currID = self.imageID9;
                    }
                    else if (self.currID == self.imageID9) {
                        self.currID = self.imageID10;
                    }
                    else if (self.currID == self.imageID10) {
                        self.currID = self.imageID1;
                    }

                    if (self.handlePhotoTimer()) {
                        checkImage();
                    }
                }, PHOTO_SHOW_DURATION);
            }
        }

        function handleImageLoaded() {
            checkImage();
        };

        let image1 = images[this.imageID1];
        let image2 = images[this.imageID2];
        let image3 = images[this.imageID3];
        let image4 = images[this.imageID4];
        let image5 = images[this.imageID5];
        let image6 = images[this.imageID6];
        let image7 = images[this.imageID7];
        let image8 = images[this.imageID8];
        let image9 = images[this.imageID9];
        let image10 = images[this.imageID10];

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>

                <div className={classes.photoFrame}>

                    <div className={classes.photoFrameImage}>
                    <PhotoFrameImage loadImage={self.loadImage1} showImage={self.state.showImage1} image={image1} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage2} showImage={self.state.showImage2} image={image2} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage3} showImage={self.state.showImage3} image={image3} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage4} showImage={self.state.showImage4} image={image4} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage5} showImage={self.state.showImage5} image={image5} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage6} showImage={self.state.showImage6} image={image6} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage7} showImage={self.state.showImage7} image={image7} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage8} showImage={self.state.showImage8} image={image8} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage9} showImage={self.state.showImage9} image={image9} handleImageLoaded={handleImageLoaded} />
                    <PhotoFrameImage loadImage={self.loadImage10} showImage={self.state.showImage10} image={image10} handleImageLoaded={handleImageLoaded} />
                    </div>

                    <div className={classes.shapes}><img src={ShapesImage} /></div>
                    <div className={classes.caligraphy}><img src={CaligraphyImage} /></div>
                </div>

            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(PhotoFramePanel)