import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import DragonsClassesHeroPanel from "./DragonsClassesHeroPanel";
import DragonsClassesPanel from "./DragonsClassesPanel";
import MerchPanel from "./MerchPanel";
import MapPanel from "./MapPanel";
import DragonsFeesPanel from "./DragonsFeesPanel";

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class DragonsClassesPage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let self = this;
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={true} ref={this.myRef} page={'classes'} />
              <DragonsClassesHeroPanel />
              <DragonsClassesPanel />
              <MapPanel />
              <DragonsFeesPanel />
              <MerchPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(DragonsClassesPage)
