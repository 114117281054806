import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './HeaderPanel';
import HeroPanel from './HeroPanel';
import BookLessonPanel from './BookLessonPanel';
import DragonsPanel from './DragonsPanel';
import YouTubePanel from './YouTubePanel';
import MerchPanel from './MerchPanel';
import SchoolPanel from './SchoolPanel';
import EventPanel from './EventPanel';
import FooterPanel from './FooterPanel';

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class WelcomePage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
        bShowMiniLogo: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    const position = window.pageYOffset;

    this.setState( {scroll: position} )

    if (position > 140 && !this.state.bShowMiniLogo) {
        this.setState( {bShowMiniLogo: true} )
    }

    if (position <= 140 && this.state.bShowMiniLogo) {
        this.setState( {bShowMiniLogo: false} )
    }
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={self.state.bShowMiniLogo} ref={this.myRef} />
              <HeroPanel />
              <BookLessonPanel />
              <DragonsPanel />
              <YouTubePanel />
              <MerchPanel />
              <SchoolPanel />
              <EventPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(WelcomePage)
