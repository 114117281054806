import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../assets/instructors/sifudamian/24WingChunAcademyLR-248.jpg";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '76px 46px 0px 46px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 64px 50px',
        },
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 26px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '64px 0px 64px 66px',
        },
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
});

class SifuDamianPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.panelContent}>

                    <div className={classes.content}>
                        <div className={classes.photoContent}>
                            <div className={classes.photo}><img src={HeroImage} /></div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.subtitle}>Damian trained for a number of years in an equivalent of a full time capacity in Sydney's China Town in an Academy headed by Master Jim Fung.</div>
                            <div className={classes.text}>
                                In 2001 he was asked to manage and teach at the then, newly established Gold Coast Academy, he would travel to Sydney regularly to train with his peers and privately under Jim Fung.
                                <br /><br />
                                In 2003 he was sent to South Australia as the State Manager of the Adelaide Academy, (est. 1973), where he ran the training of the general club teaching up to 6 classes a day, running full-time accredited courses for local and international students, as well as delivering workshops to schools, commercial businesses, community organisations and training institutions. Damian has a wealth of knowledge about the Wing Chun System and the technical aspects of application.
                                <br /><br />
                                Damian also takes pride in his skill set as a teacher, studying this alongside the development of his Wing Chun, he has the capacity to be able to relay information concisely to an array of learners and help students achieve the goals they set out to achieve, he works as a teacher in other fields outside of Wing Chun. Damian has more than a 24 years experience running Wing Chun training centres, He is recognised by the Ving Tsun Athletic Association as a Sifu of the system, he was a Senior Instructor Level 3 under the Jim Fung linage, he holds Certificate IV in Wing Chun Instruction, Diploma of Sport [Coaching], Certificate IV of Fitness, Certificate IV in Assessment and Workplace Training.
                                <br /><br />
                                Damian spent 5 years promoting full contact fight shows, showcasing MMA, Kickboxing, Muay Thai and San Shou, and was instrumental in bringing MMA to the South Australian public.
                            </div>

                            <div className={classes.more}><Link to="/sifutony">Read about Sifu Tony Psaila</Link></div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(SifuDamianPanel)