import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './HeaderPanel';
import FooterPanel from './FooterPanel';
import FreeLessonHeroPanel from "./FreeLessonHeroPanel";
import FreeLessonPanel from "./FreeLessonPanel";
import MerchPanel from "./MerchPanel";
import YouTubePanel from "./YouTubePanel";
import MapPanel from "./MapPanel";

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class FreeLessonPage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let self = this;
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={true} ref={this.myRef} />
              <FreeLessonHeroPanel />
              <FreeLessonPanel />
              <MerchPanel />
              <YouTubePanel />
              <MapPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(FreeLessonPage)
