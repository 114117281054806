import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ShapesImage from "../assets/shapes.png";
import VideoPlayerPanel from "./VideoPlayerPanel";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '0',
                    borderRadius: '4',
                    minWidth: '0',
                    width: '48px',
                    height: '40px',
                },
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#009BB5",
                }
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',

        '&::after': {
            content: '" "',
            backgroundImage: 'url(' + ShapesImage + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '143px 113px',
            width: '143px',
            height: '113px',
            position: 'absolute',
            top: '-57px',
            left: 'calc(50% - 72px)',

            [theme.breakpoints.up('lg')]: {
                backgroundSize: '239px 189px',
                width: '239px',
                height: '189px',
                top: 'calc(50% - 95px)',
                left: 'calc(50% - 790px)',
            }
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    videoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '80px 36px 0px 36px',

        [theme.breakpoints.up('lg')]: {
            padding: '100px 0px 0px 0px',
        }
    },
    textContent: {
        textAlign: 'left',
        padding: '20px 16px 60px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '62px 100px 62px 50px',
        },
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        color: '#FFF',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #8C7356 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
    photoFrameMobile: {
        display: 'block',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    photoFrameDesktop: {
        display: 'none',

        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    }
});

class YouTubePanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.content}>

                        <div className={classes.photoFrameMobile}>
                            <div className={classes.videoContent}>
                                <VideoPlayerPanel />
                            </div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.title}>Find us on our YouTube channel</div>
                            <div className={classes.subtitle}>Dive deeper into the art of Wing Chun with in depth explanations and demonstrations by our senior instructors.</div>
                            <div className={classes.text}>Sifus Tony Psaila and Damian Rainer demonstrate the principles of Wing Chun - Simplicity, Directness, Practicality, Economy of Movement and Minimum Use of Brute Force.</div>
                            <div className={classes.more}><a href={"https://www.youtube.com/@adelaidewingchunacademy3590"} target="_blank">Watch videos</a></div>
                        </div>

                        <div className={classes.photoFrameDesktop}>
                            <div className={classes.videoContent}>
                                <VideoPlayerPanel />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(YouTubePanel)