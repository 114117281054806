import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../assets/school/hero.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#009BB5",
                }
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    content: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 0px 0px',
        }
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '20px 16px 46px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 100px 76px 50px',
        },
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    photoFrameMobile: {
        display: 'block',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    photoFrameDesktop: {
        display: 'none',

        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    }
});

class SchoolPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.content}>

                        <div className={classes.photoFrameMobile}>
                            <div className={classes.photoContent}>
                                <div className={classes.photo}><img src={HeroImage} /></div>
                            </div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.title}>School incursions</div>
                            <div className={classes.subtitle}>Improve fitness, coordination, concentration and confidence at your school.</div>
                            <div className={classes.text}>Build child oriented awareness strategies and self defence based on Wing Chun concepts.  We can work with both school classes and OSCH.</div>
                            <Link to="/contact"><Button variant="contained">Contact us</Button></Link>
                        </div>

                        <div className={classes.photoFrameDesktop}>
                            <div className={classes.photoContent}>
                                <div className={classes.photo}><img src={HeroImage} /></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(SchoolPanel)