import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Link} from "react-router-dom";
import Reviews from "../assets/google_placeholder.png";
import Logo from "../assets/logo_footer.png";
import { FaFacebookF } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '8px 16px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    content: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 32px 48px 32px',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '1082px',
        }
    },
    reviews: {
        alignSelf: 'flex-start',
        padding: '0 0 0 0',

        [theme.breakpoints.up('lg')]: {
            paddingTop: '50px',
        }
    },
    branches: {
        alignSelf: 'flex-start',
        padding: '20px 0 0 0',

        [theme.breakpoints.up('lg')]: {
            paddingTop: '50px',
        }
    },
    contact: {
        alignSelf: 'flex-start',
        padding: '20px 0 0 0',

        [theme.breakpoints.up('lg')]: {
            paddingTop: '50px',
        },

        '& a': {
            color: '#FFF',
            textDecoration: 'none',
        },
    },
    social: {
        padding: '20px 0 0 8px',
    },
    logo: {
        padding: '20px 0 0 0',
        width: '248px',

        [theme.breakpoints.up('lg')]: {
            paddingTop: '0',
            width: '300px',
        },

        '& img': {
            width: '100%',
            mixBlendMode: 'screen',
        }
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '0 0 4px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        color: '#FFF',
    },
    svgIcons: {
        padding: '0 18px 0 0',
        transform: 'scale(1.8)',
    }
});

class FooterPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.content}>

                        <div className={classes.reviews}><img src={Reviews} /></div>
                        <div className={classes.branches}>
                            <div className={classes.subtitle}>Branches</div>
                            <div className={classes.text}>
                            City Branch<br />
                            St Luke's Hall<br />
                            35 Whitmore Square<br />
                            Adelaide<br />
                            </div>
                        </div>
                        <div className={classes.contact}>
                            <div className={classes.subtitle}>Contact us</div>
                            <div className={classes.text}>0438 891 562<br />
                            <a href={"mailto:info@adelaidewingchunacademy.com.au"} target="_blank">info@adelaidewingchunacademy.com.au</a><br />
                            <div className={classes.social}>
                                <a href={"https://www.facebook.com/profile.php?id=100039030530760"} target="_blank"><FaFacebookF className={classes.svgIcons}/></a>
                                <a href={"https://www.youtube.com/@adelaidewingchunacademy3590"} target="_blank"><TfiYoutube className={classes.svgIcons}/></a>
                            </div>
                            </div>
                        </div>
                        <div className={classes.logo}><Link to="/"><img src={Logo} /></Link></div>

                    </div>

                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(FooterPanel)