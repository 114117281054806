import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Link} from "react-router-dom";
import Logo from "../assets/logo.png";
import PhotoFramePanel from './PhotoFramePanel';
import CaligraphyImage from "../assets/caligraphy.png";

const muiTheme = createTheme({
    palette: {
        custom: {
            main: '#FFF'
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#009BB5",
                }
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        marginTop: '-50px',
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#0D0D0D',

        [theme.breakpoints.up('lg')]: {
            marginBottom: '150px',
        }
    },
    effectLogo: {
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('lg')]: {
            backgroundImage: 'url(' + CaligraphyImage + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '105px 204px',
            backgroundPosition: 'calc(50% + 494px) calc(50% + 18px)',
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            width: '982px',
        }
    },
    logo: {
        margin: '0 0 24px 0',
        
        '& img': {
          width: '300px',

            [theme.breakpoints.up('md')]: {
                margin: '0 0 54px 0',
                width: '502px',
            },

            [theme.breakpoints.up('lg')]: {
                margin: '0 0 24px 0',
            },
        }
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
            marginTop: '90px',
            alignItems: 'start',
            flexDirection: 'row',
        }
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 100px 16px',

        [theme.breakpoints.up('sm')]: {
            padding: '48px 16px 100px 16px',
        },

        [theme.breakpoints.up('md')]: {
            padding: '106px 16px 100px 16px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '20px 130px 0px 0px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '3.2em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#EFC28F',
    },
    text: {
        color: '#EFC28F',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
    photoFrameMobile: {
        display: 'block',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    photoFrameDesktop: {
        display: 'none',

        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    }
});

class HeroPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { classes } = this.props;
        let self = this;

        function handlePhotoTimer() {
            return self.mounted;
        };

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>

                <div className={classes.content}>

                    <div className={classes.logo}><img src={Logo}/></div>

                    <div className={classes.panelContent}>

                        <div className={classes.photoFrameMobile}><PhotoFramePanel handlePhotoTimer={handlePhotoTimer} /></div>

                        <div className={classes.textContent}>
                            <div className={classes.title}>Welcome to the Adelaide Wing Chun Academy</div>
                            <div className={classes.subtitle}>We focus on delivering the traditional principles of the art of Wing Chun with contemporary application.</div>
                            <div className={classes.text}>The Academy offers a friendly and supportive environment for the individual, where they will grow in ability holistically for the mind and body, including development of their personal self-defence, fitness, coordination and concentration, and overall improving confidence throughout many areas of life.</div>
                            <div className={classes.more}><Link to="/about">About us</Link></div>
                        </div>

                        <div className={classes.photoFrameDesktop}><PhotoFramePanel handlePhotoTimer={handlePhotoTimer} /></div>

                    </div>

                    <div className={classes.effectLogo}></div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(HeroPanel)